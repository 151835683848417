<template>
    <v-form class="sidebar-container" @submit.prevent="">
        <div class="sidebar-container__section">
            <div class="sidebar-container__input-group">
                <h3 class="sidebar-container__header">Supplier</h3>
                <DropdownPrimarySupplier
                    v-model="supplier"
                    outlined
                    :hideDetails="true"
                />
            </div>

            <!-- Location Selection -->
            <div class="sidebar-container__input-group">
                <h3 class="sidebar-container__header">Location</h3>
                <DropdownLocationList
                    v-model="location"
                    clearable
                    outlined
                />
            </div>
        </div>

        <!-- EAN -->
        <div id="ean-filter" class="sidebar-container__section">
            <div class="sidebar-container__input-group">
                <h3 class="sidebar-container__header">EAN</h3>
                <v-text-field
                    v-model="ean"
                    type="text"
                    dense
                    hideDetails
                    backgroundColor="white"
                    placeholder="EAN"
                    outlined
                />
            </div>
        </div>

        <!-- RRA Number -->
        <div class="sidebar-container__section book-data-section">
            <div class="sidebar-container__input-group">
                <h3 class="sidebar-container__header">RRA #</h3>
                <v-text-field
                    v-model="rraNumber"
                    type="text"
                    dense
                    hideDetails
                    backgroundColor="white"
                    name="rra-number"
                    placeholder="RRA Number"
                    outlined
                />
            </div>

            <!-- RRA Date -->
            <div class="search-parameter sidebar-container__input-group">
                <h3 class="sidebar-container__header">RRA Date</h3>
                <div class='search-parameter__row'>
                    <InputDateRange
                        :start-date.sync="createStartDate"
                        :end-date.sync="createEndDate"
                        label="RRA Date"
                        outlined
                    />
                </div>
            </div>
        </div>
        <div class="sidebar-container__section">
            <div class="sidebar-container__input-group status-section">
                <h3 class="sidebar-container__header">Status</h3>
                <CheckboxMultiSelectPersistable
                    v-model="statuses"
                    :leftColumnKeys="leftColumnKeys"
                />
            </div>
        </div>
        <div class="sidebar-container__section">
            <!-- NOTES -->
            <div class="sidebar-container__input-group">
                <span class="sidebar-container__input-header">
                    <h3 class="sidebar-container__header">RRA Notes</h3>
                    <ToolTip class="tooltip">
                        <v-icon slot="target" class="tooltip__icon">mdi-information-outline</v-icon>
                        <div slot="content">
                            May increase search time
                        </div>
                    </ToolTip>
                </span>
                <span>
                    <v-text-field
                        v-model="notes"
                        type="text"
                        dense
                        hideDetails
                        placeholder="Starts with"
                        backgroundColor="white"
                        outlined
                    />
                </span>
            </div>
        </div>

        <!-- Action Buttons -->
        <div
            id="button-container"
            class="sidebar-container__section"
        >
            <v-btn
                outlined
                color="white"
                @click="clearFilters()"
            >
                Clear
            </v-btn>

            <v-btn
                class="button-search"
                type="submit"
                @click="search()"
            >
                Search
            </v-btn>
        </div>
    </v-form>
</template>

<script>
// Vuex
import { mapState } from 'vuex';

// Constants
import { STATUS_LIST_COLUMNS } from '@/constants/rras/statusList';

// Components
import DropdownPrimarySupplier from '@/components/dropdowns/DropdownPrimarySupplier';
import DropdownLocationList from '@/views/common/dropdowns/DropdownLocationList';
import CheckboxMultiSelectPersistable from '@/components/base/multiselect/CheckboxMultiSelectPersistable';
import ToolTip from '@/components/base/tooltip/ToolTip';
import InputDateRange from '@/components/InputDateRange';

export default {
    name: 'SidebarFilterRRAs',
    components: {
        InputDateRange,
        DropdownPrimarySupplier,
        DropdownLocationList,
        CheckboxMultiSelectPersistable,
        ToolTip,
    },
    computed: {
        ...mapState( {
            rraStatuses: ( state ) => state.RRA.Search.rraStatuses,
        } ),
        /** @returns { String[] } */
        leftColumnKeys() {
            return this.rraStatuses
                .filter( ( status ) => STATUS_LIST_COLUMNS[ status.key ] === 0 )
                .map( ( status ) => status.key );
        },
        supplier: {
            get() {
                return this.$store.state.RRA.Search.filters.supplier;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterSupplier', { supplier: val } );
            },
        },
        createStartDate: {
            get() {
                return this.$store.state.RRA.Search.filters.createStartDate;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterCreateStartDate', { createStartDate: val } );
            },
        },
        createEndDate: {
            get() {
                return this.$store.state.RRA.Search.filters.createEndDate;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterCreateEndDate', { createEndDate: val } );
            },
        },
        statuses: {
            get() {
                return this.$store.state.RRA.Search.filters.statuses;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterStatuses', { statuses: val } );
            },
        },
        rraNumber: {
            get() {
                return this.$store.state.RRA.Search.filters.number;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterNumber', { number: val } );
            },
        },
        ean: {
            get() {
                return this.$store.state.RRA.Search.filters.ean;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterEan', { ean: val } );
            },
        },
        location: {
            get() {
                return this.$store.state.RRA.Search.filters.location;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterLocation', { location: val } );
            },
        },
        notes: {
            get() {
                return this.$store.state.RRA.Search.filters.notes;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setFilterNotes', { notes: val } );
            },
        },
    },
    beforeMount() {
        this.$store.dispatch( 'GlobalResources/init' );
    },
    methods: {
        search() {
            this.$store.dispatch( 'RRA/Search/searchRRAs' );
        },
        clearFilters() {
            this.$store.dispatch( 'RRA/Search/clearFilters' );
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/assets/sass/functions.scss";

.sidebar-container__section:last-of-type {
    display: flex;
    justify-content: space-around;
}

.sidebar-container {
    & &__input-group:not(:first-of-type) {
        margin-top: 1rem;
    }
    & &__input-header {
        display: flex;
        justify-content: space-between;
    }
}

.search-parameter {
    &__row {
        display: flex;
        align-items: center;
        .v-input {
            margin-top: 0;
            width: rem-calc(165);
        }
        p {
            margin: 0 0.5rem 0;
        }
    }
}

.status-section {
    display:grid;
    grid-template-columns: 25% auto;
}
</style>
