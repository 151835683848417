<template>
    <div class="sidebar-full custom-container">
        <TheSidebar fullSize>
            <SidebarFilterRRAs
                @showSummaryView="$router.push( { name: 'RRASearch' } )"
            />
        </TheSidebar>

        <ThePageHeadingBar
            pageHeading="RRA Search"
            fullSize
            @goBack="$router.push( { name: 'RRASearch' } )"
        />

        <section class="main">
            <!-- RRA Summary View -->
            <SummaryView />
        </section>
    </div>
</template>

<script>
// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';
import TheSidebar from '@/views/common/TheSidebar';
import SidebarFilterRRAs from './search/SidebarFilterRRAs';
import SummaryView from './search/SummaryView';

export default {
    name: 'RRASearch',
    components: {
        TheSidebar,
        ThePageHeadingBar,
        SidebarFilterRRAs,
        SummaryView,
    },
};
</script>

<style scoped>
</style>
