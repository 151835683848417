<template>
    <div>
        <ClTableFilterable
            class="filterable-table"
            :filter.sync="tableFilter"
            :selected.sync="tableSelectedList"
            :headers="headers"
            :items="searchInfo.rraList"
            :loading="inFlight"
            :sortBy.sync="tableSortBy"
            :sortDesc.sync="tableSortDesc"
            :noDataText="( inFlight ? 'Searching for RRAs...': 'No RRAs Found Matching Search Criteria' )"
            cardElevation="3"
            itemKey="number"
            showSelect
            filterLabel="Filter RRAs..."
            hideAdd
            @click:row="( e, { item } ) => rowClickHandler( item )"
            @current-items="( payload ) => updateCurrentItems( payload )"
        >
            <template #[`item.createdDate`]="{ item }">
                {{ item.createdDate ? FormatUtil.dateTimeToSimpleString( item.createdDate ) : 'None' }}
            </template>
            <template #[`item.friendlyStatus`]="{ item }">
                <div class="single-line">
                    {{ item.friendlyStatus }}
                    <v-icon
                        v-if="item.isTransactionComplete"
                        small
                    >
                        mdi-truck
                    </v-icon>
                </div>
            </template>
            <template #[`item.totalValue`]="{ item }">
                {{ FormatUtil.toCurrency( item.totalValue ) }}
            </template>
        </ClTableFilterable>

        <ClModal
            v-model="showJasperReportModal"
            persistent
            maxWidth="50rem"
        >
            <v-card class="jasper-report-modal">
                <v-card-title class="jasper-report-modal__header">
                    <h2 class="jasper-report-modal__title">
                        {{ jasperReportModalHeaderText }}
                    </h2>
                    <v-spacer />
                    <v-btn
                        class="jasper-report-modal__header__close-button"
                        aria-label="Close"
                        icon
                        @click="showJasperReportModal = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="jasper-report-modal__body">
                    <ClTableSimple
                        :headers="jasperReportHeaders"
                        :items="jasperReports"
                        @click:row="( jasperReport ) => openUrl( jasperReport.reportUrl )"
                    >
                        <template #[`item.totalRRAs`]="{ item }">
                            {{ item.rraNumbers.length }}
                        </template>
                        <template #[`item.rraNumbers`]="{ item }">
                            <div class="jasper-report-modal__rraNumber-column">
                                {{ item.rraNumbers.join( ", " ) }}
                            </div>
                        </template>
                    </ClTableSimple>
                </v-card-text>
            </v-card>
        </ClModal>
        <!-- Page Footer Bar -->
        <ThePageFooterBar>
            <!-- Print RRA -->
            <div class="footer-actions">
                <ClButton
                    type="primary"
                    :rounded="false"
                    @click="$router.push( { path: '/rra/create', query: {
                        supplierId: searchInfo.filters.supplier.id,
                        locationId: searchInfo.filters.location.id,
                    } } )"
                >
                    Create
                </ClButton>
                <!-- Print RRA -->
                <ClButton
                    type="primary"
                    :rounded="false"
                    @click="getPicklists()"
                >
                    Picklist
                </ClButton>
                <!-- Print RRA -->
                <ClButton
                    type="primary"
                    :rounded="false"
                    @click="getJasperPrintReports()"
                >
                    Print
                </ClButton>
            </div>
        </ThePageFooterBar>
    </div>
</template>
<script>
// Vuex
import { mapState } from 'vuex';

// Utils
import _ from 'lodash';
import FormatUtil from '@/utils/FormatUtil';
import DateUtil from '@/utils/DateUtil';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';

export default {
    name: 'SummaryView',
    components: {
        ThePageFooterBar,
    },
    data() {
        return {
            showJasperReportModal: false,
            jasperReportModalHeaderText: '',
            jasperReports: [],
            jasperReportHeaders: [
                {
                    text: 'Location',
                    value: 'location.name',
                },
                {
                    text: 'Total RRAs',
                    value: 'totalRRAs',
                    align: 'end',
                },
                {
                    text: 'RRAs',
                    value: 'rraNumbers',
                },
            ],
            headers: [
                {
                    text: 'RRA Number',
                    value: 'number',
                },
                {
                    text: 'Supplier',
                    value: 'supplier.name',
                },
                {
                    text: 'Location',
                    value: 'location.name',
                },
                {
                    text: 'Date',
                    value: 'createdDate',
                    sort: ( a, b ) => DateUtil.sortDays( a, b ),
                },
                {
                    text: 'Status',
                    value: 'friendlyStatus',
                },
                {
                    text: 'Total Qty',
                    align: 'end',
                    value: 'totalItems',
                },
                {
                    text: 'Total Value',
                    align: 'end',
                    value: 'totalValue',
                },
            ],
            FormatUtil,
        };
    },
    computed: {
        ...mapState( {
            inFlight: ( state ) => state.RRA.Search.inFlight,
            searchInfo: ( state ) => state.RRA.Search,
        } ),
        tableFilter: {
            get() {
                return this.searchInfo.table.filter;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setTableFilter', { filter: val } );
            },
        },
        tableSelectedList: {
            get() {
                return this.searchInfo.table.selected;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setTableSelected', { selected: val } );
            },
        },
        tableSortBy: {
            get() {
                return this.searchInfo.table.sortBy;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setTableSortBy', { sortBy: val } );
            },
        },
        tableSortDesc: {
            get() {
                return this.searchInfo.table.sortDesc;
            },
            set( val ) {
                this.$store.dispatch( 'RRA/Search/setTableSortDesc', { sortDesc: val } );
            },
        },
    },
    methods: {
        updateCurrentItems( items ) {
            if ( !_.isEqual( this.searchInfo.rraList, items ) ) {
                this.$store.commit( 'RRA/Search/SET_RRA_TABLE_LIST', { rraList: items } );
            }
        },
        async getPicklists() {
            this.jasperReportModalHeaderText = 'Picklist Report';
            this.jasperReports = await this.$store.dispatch( 'RRA/Search/getPicklists', { rras: this.tableSelectedList } );
            if ( this.jasperReports.length > 1 ) {
                this.displayJasperReportModal();
            } else if ( this.jasperReports.length === 1 ) {
                this.openUrl( this.jasperReports[ 0 ].reportUrl );
            }
        },
        async getJasperPrintReports() {
            this.jasperReportModalHeaderText = 'Print Report';
            this.jasperReports = await this.$store.dispatch( 'RRA/Search/getPrintReports', { rras: this.tableSelectedList } );
            if ( this.jasperReports.length > 1 ) {
                this.displayJasperReportModal();
            } else if ( this.jasperReports.length === 1 ) {
                this.openUrl( this.jasperReports[ 0 ].reportUrl );
            }
        },
        displayJasperReportModal() {
            this.showJasperReportModal = true;
        },
        openUrl( url ) {
            window.open( url, '_blank' );
        },
        rowClickHandler( item ) {
            this.$router.push( {
                name: 'RRADetail',
                params: { id: `${ item.id.toString() }` },
            } );
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/sass/variables';

::v-deep .selected-border td:first-child {
    border-left: 4px solid var(--v-primary-base);
}

.filterable-table {
    margin-bottom: 7rem;
}

.single-line {
    white-space: nowrap;
}

.footer-actions {
    & > *:not(:first-child) {
        margin-left: 2rem;
    }
}

.jasper-report-modal {
    & &__header {
        padding: 1.25rem 1.5rem;
        color: white;
        background-color: $primary;
        .v-icon {
            color: white;
        }
    }

    & &__body {
        padding: .75rem 1rem;
        max-height: 50vh;
        overflow-y: auto;
    }

    &__rraNumber-column {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 20rem;
    }
}
</style>
